<template>
  <div class="main-container">
    <h1 class="dark-text fs-18 fw-700" style="margin: 25px 0 10px">
      Upload<span style="color: #6a1b9a !important"> / Coaching Video</span>
    </h1>
    <div class="row">
      <CourseSubjectTopicChapterSearchVue
        :showAddModalFromParent="showAddModal"
        :isSearchButtonClicked="searchClicked"
        :showBatchDropDown="false"
        :isUploadButtonClicked="uploadClicked"
        :isMapExistingClicked="existingClicked"
        :openMapExistingModal="openExistingModal"
        @propsChanged="getChangedObject($event)"
        @modalClosed="modalClosedEvent()"
        @existingModalClosed="closeMapExistingModal()"
        @videoMappedEvent="videoMappedEvent($event)"
        @searchValidations="searchValidationsCompleted($event)"
        @videoUploadEvent="onVideoUploadEvent($event)"
      >
      </CourseSubjectTopicChapterSearchVue>
      <div class="col l2 s12 m2 offset-l2" style="margin-top: 10px">
        <button
          class="add-btn w-100"
          style="background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%)"
          @click="startSearch()"
        >
          Search Video
        </button>
      </div>
      <div class="col l2 s12 m2" style="margin-top: 10px">
        <button
          class="add-btn w-100"
          style="background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%)"
          @click="showAddModalActions()"
        >
          Upload Video
        </button>
      </div>
      <div class="col l2 s12 m2" style="margin-top: 10px">
        <button
          class="add-btn w-100"
          style="background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%)"
          @click="showExistingModalActions()"
        >
          Map Existing Video
        </button>
      </div>
    </div>
    <div
      class="scroll-verticle-bar"
      style="max-height: calc(100vh - 310px)"
      v-if="videoList.length > 0"
    >
      <div
        class="white test-list"
        v-for="course in videoList[0]"
        :key="course.Id"
        style="margin-bottom: 20px"
      >
        <h2 class="fs-16 fw-600 truncate black-text">
          <span :title="course.VideoTitle">{{ course.VideoTitle }}</span>
          <span :title="'Video Id: ' + course.CoachingVideoId">
            &nbsp;(VideoId-{{ course.CoachingVideoId }})</span
          >
        </h2>
        <div class="row test-details flex items-center mb-0">
          <div class="col s12 m12 l2 p-0" style="margin-left: 0">
            <h2
              class="fs-13 fw-500 gray-text-clr line-margin flex items-center"
              :title="'Course: ' + course.CourseName"
            >
              <img
                :src="require(`../assets/homework 1.svg`)"
                alt=""
                style="margin-right: 6px"
              /><span>{{ course.CourseName }}</span>
            </h2>
            <h2
              class="fs-13 fw-500 gray-text-clr line-margin flex items-center"
              :title="'Subject: ' + course.SubjectName"
            >
              <img
                :src="require(`../assets/testHistory-books.svg`)"
                alt=""
                style="margin-right: 6px"
              /><span>{{ course.SubjectName }}</span>
            </h2>
          </div>
          <div class="col s12 m12 l2 p-0">
            <h2
              class="fs-13 fw-500 gray-text-clr line-margin flex items-center topic"
              :title="course.TopicName"
            >
              <img :src="require(`../assets/testHistory-openBook.svg`)" class="icon-bullet" />
              <span class="pl-5">{{
                course.TopicName !== undefined ? formatString(course.TopicName) : null
              }}</span>
            </h2>
            <h2
              class="fs-13 fw-500 gray-text-clr line-margin flex items-center"
              :title="course.ChapterName"
            >
              <img :src="require(`../assets/testHistory-bookmark.svg`)" class="icon-bullet" />
              <span class="pl-5">{{
                course.ChapterName !== undefined ? formatString(course.ChapterName) : null
              }}</span>
            </h2>
          </div>
          <div class="col m7 s12 l2 text-div p-0 truncate" :title="formatDate(course.CreatedOn)">
            <img :src="require(`../assets/testHistory-calendar.svg`)" class="icon-bullet" />
            <span class="fs-13 fw-500 pl-5">{{ formatDate(course.CreatedOn) }}</span>
          </div>
          <div class="col s12 m6 l1 p-0 flex flex-end items-center action-icons">
            <div class="flex flex-end items-center action-icons">
              <p
                class="flex fs-13 fw-500 light-gray-text tooltip pointer"
                style="margin-left: 16px; height: 23px"
                @click="deleteFile(course.CoachingVideoId)"
              >
                <img
                  :src="require(`../assets/delete-new-icon.svg`)"
                  alt=""
                  style="width: 23px"
                  class="pointer"
                />
                <span class="tooltiptext" style="width: 65px !important">Delete</span>
              </p>
              <p
                class="flex fs-13 fw-500 light-gray-text tooltip pointer"
                style="margin-left: 16px; height: 23px"
                @click="whiteListVideoEvent(course.CoachingVideoId)"
              >
                <img
                  v-if="course.VideoURL2.includes('vimeo')"
                  :src="require(`../assets/whitelist.png`)"
                  alt=""
                  style="width: 23px"
                  class="pointer"
                />
                <span class="tooltiptext" style="width: 65px !important">Whitelist</span>
              </p>
            </div>
          </div>
          <div class="col m7 s12 l2 p-0 column-3 video">
            <vimeo-player
              v-if="course.VideoURL2.includes('vimeo')"
              :video-url="course.VideoURL2"
              frameborder="0"
              :player-width="200"
              :player-height="80"
            ></vimeo-player>
            <video
              v-if="course.VideoURL2.includes('dataimagestorage')"
              controls="true"
              controlslist="nodownload"
              width="200"
              height="80"
              loop="true"
            >
              <source :src="course.VideoURL2" type="video/mp4" />
            </video>
            <iframe
              v-if="course.VideoURL2.includes('youtube')"
              width="200"
              height="80"
              :src="course.VideoURL2"
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center error-box">
      <img
        :src="require(`../assets/no-result-found.png`)"
        alt=""
        style="width: 200px; opacity: 0.7"
      />
      <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585">No videos available.</p>
    </div>
    <div v-if="videoList.length > 0" class="pagination flex flex-between d-block">
      <div>
        <span class="show-index fw-500 fs-15 ws-nowrap">
          <span>Showing </span>
          <span>{{ videoList[0].length }}</span>
          <span> Videos</span>
        </span>
      </div>
    </div>
    <Modal :show="showDeleteModal" :showHeader="false" @close="showDeleteModal = false">
      <template v-slot:body>
        <div class="warning-section-modal">
          <div class="flex flex-center" style="margin-bottom: 20px; padding-top: 30px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" style="width: 120px" />
          </div>
          <div class="flex flex-center" style="flex-direction: column">
            <span class="mesage-body fs-14 fw-500 dark-text" style="white-space: pre-line">
              Are you sure you want delete this file?
            </span>
          </div>
          <div class="flex content-even" style="padding: 20px">
            <button class="confirm-btn fs-13 fw-600 ln-18" @click="deleteInstituteVideo()">
              YES
            </button>
            <button class="cancel-btn fs-13 fw-600 ln-18" @click="showDeleteModal = false">
              NO
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import moment from "moment";
// import Vue from "vue";
import CourseSubjectTopicChapterSearchVue from "../components/CourseSubjectTopicChapterSearch.vue";
import MobileAPI from "../Mobileapi";
// import API from "../Api";
import Doubtapi from "../Doubtapi";
import Modal from "../components/Modal.vue";

const toast = useToast();

// Vue.filter("formatDate", (value) => {
//   if (value) {
//     return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY HH:mm:ss a");
//   }
//   return value;
// });

export default {
  components: { CourseSubjectTopicChapterSearchVue, Modal },

  data() {
    return {
      showMsg: false,
      showDeleteModal: false,
      showAddModal: false,
      isDateRangeOpen: false,
      showmessage: true,
      uploadfile: false,
      coursedata: [],
      batchData: [],
      subjectData: [],
      chapter: [],
      topic: [],
      topicData: {},
      affiliationCourse: 0,
      affiliationSubject: 0,
      affiliationTopic: 0,
      affiliationChapter: 0,
      affiliationBatch: null,
      affiliationTitle: null,
      courseValidation: false,
      subjectValidation: false,
      topicvalidation: false,
      chapterValidation: false,
      batchValidation: false,
      documentValidation: false,
      courseId: "",
      subjectId: "",
      topicId: "",
      chapterId: "",
      batchId: "",
      classId: null,
      selectedBatches: [],
      videoList: [],
      deleteCoachingVideo: [],
      whiteListVideo: [],
      affiliationCourseId: "",
      titleValidation: false,
      fileValidation: false,
      coachingId: null,
      videoId: [],
      selectedProps: {
        courseId: 0,
        subjectId: 0,
        topicId: 0,
        chapterId: 0,
        courseChapterId: 0,
        batches: "",
      },
      searchClicked: false,
      uploadClicked: false,
      mapExistingClicked: false,
      existingClicked: false,
      openExistingModal: false,
    };
  },
  beforeMount() {
    document.body.style.backgroundColor = "#F6F8FB";
  },
  activated() {
    this.$store.dispatch("showLoader", true);
    // API.getInstituteTestMeta({}, (response) => {
    //   this.$store.dispatch("showLoader", false);
    //   this.coursedata = response.courses;
    // });
    const obj = {
      onlyCourses: 1,
      courseId: 0,
    };
    MobileAPI.getCourseMetaData(this.$store.getters.user.AffiliationId, obj, (response) => {
      this.$store.dispatch("showLoader", false);
      this.coursedata = response.data[0];
    });
  },
  methods: {
    getChangedObject(data) {
      this.selectedProps = JSON.parse(data);
    },
    modalClosedEvent() {
      this.showAddModal = false;
    },
    showAddModalActions() {
      this.uploadClicked = true;
    },
    showExistingModalActions() {
      this.existingClicked = true;
    },
    closeMapExistingModal() {
      this.openExistingModal = false;
    },
    resetValues() {
      this.affiliationCourse = 0;
      this.affiliationSubject = 0;
      this.affiliationTopic = 0;
      this.affiliationChapter = 0;
      this.affiliationBatch = null;
      this.affiliationTitle = null;
      this.videoList = [];
    },
    deleteFile(id) {
      this.showDeleteModal = true;
      this.coachingId = id;
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY HH:mm a");
      }
      return value;
    },
    formatString(string) {
      const str = string.split(",");
      if (str.length === 1) {
        if (str[0].length > 20) {
          str[0] = str[0].slice(0, 20);
          return `${str[0]}..`;
        }
        return str[0];
      }
      if (str[0].length > 20) {
        str[0] = str[0].slice(0, 20);
      }
      return `${str[0]}... +${str.length - 1}`;
    },
    searchValidationsCompleted(event) {
      const childData = JSON.parse(event);
      this.showmessage = childData.result;
      this.searchClicked = false;
      this.uploadClicked = false;
      this.existingClicked = false;
      if (childData.isMapExistingVideo === true) {
        if (!this.showmessage) {
          this.openExistingModal = true;
        }
      } else if (childData.isSearch === false) {
        if (!this.showmessage) {
          this.showAddModal = true;
        }
      } else if (!this.showmessage) {
        this.searchCoachingVideo();
      }
    },
    startSearch() {
      this.searchClicked = true;
    },
    searchCoachingVideo() {
      const user = this.$store.getters.user;
      const data = {
        AffiliationId: Number(user.AffiliationId),
        CourseId: this.selectedProps.courseId,
        SubjectId: this.selectedProps.subjectId,
        TopicId: this.selectedProps.topicId,
        ChapterId: this.selectedProps.chapterId,
        CourseChapterId: this.selectedProps.courseChapterId,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.getInstituteVideos(data, (response) => {
        this.$store.dispatch("showLoader", false);
        this.videoList = response.data;
        if (this.videoList.length === 0) {
          toast.info(
            "No records found for your current selection. Please try a different selection",
            {
              timeout: 2500,
            },
          );
        }
      });
      this.$store.dispatch("showLoader", false);
    },
    whiteListVideoEvent(File) {
      const data = {
        CoachingVideoId: File,
      };
      // console.log(data);
      this.$store.dispatch("showLoader", true);
      Doubtapi.whiteListVideo(data, (response) => {
        if (response.statusCode === 500) {
          this.$store.dispatch("showLoader", false);
          toast.info("Whitelist operation failed. please try again.", {
            timeout: 2500,
          });
        } else if (response.statusCode === 200) {
          this.$store.dispatch("showLoader", false);
          toast.success("Video is whitelisted. Please login again to reflect changes.", {
            timeout: 2500,
          });
        }
        this.whiteListVideo = response.data;
      });
    },
    deleteInstituteVideo() {
      const data = {
        CoachingVideoId: this.coachingId,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.deleteCoachingVideo(data, (response) => {
        this.$store.dispatch("showLoader", false);
        this.searchCoachingVideo();
        if (response.responseCode === 200) {
          toast.success("Deleted Successfully", {
            timeout: 2500,
          });
        }
      });
      this.showDeleteModal = false;
    },
    onVideoUploadEvent(event) {
      this.showAddModal = event;
      if (this.showAddModal === false) {
        this.searchCoachingVideo();
      }
    },
    videoMappedEvent(event) {
      if (event) {
        this.searchCoachingVideo();
      }
    },
  },
};
</script>

<style scoped>
.icon-bullet {
  width: 24px;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import "https://unpkg.com/vue-multiselect@2.0.2/dist/vue-multiselect.min.css";
@import url("https://cdn.jsdelivr.net/npm/vue3-treeselect@^0.1.1/dist/vue3-treeselect.min.css");

* {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box !important;
}

/* custom css */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.tooltip {
  position: relative;
}

.dark-text {
  color: #333333;
}

.line-margin {
  margin: 7px 0;
}

.gray-text-clr {
  color: #333333;
}

/* main css */
.main-container {
  margin-top: 20px;
  padding: 0 10px;
}

.test-list {
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 6px;
  padding: 12px 17px;
}

.add-btn {
  font-weight: 600;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 7px 18px;
  cursor: pointer;
}

input[type="text"] {
  border: 1px solid #e0e4f0 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  padding: 2px 10px;
  margin: 0;
  /* border-bottom: none !important; */
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  /* border-bottom: none !important; */
  box-shadow: none;
}

input[type="text"]:not(.browser-default) {
  height: 43px !important;
  /* border-bottom: none !important; */
}

select {
  border: 1px solid #e0e4f0 !important;
  background-color: white !important;
  height: 43px;
  border-radius: 4px;
  font-size: 14px;
  color: #adadad;
}

select:focus {
  outline: none;
}

#select-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../assets/Vector4.svg") no-repeat right;
  background-size: 38px 6px;
  height: 43px;
  padding-left: 7px;
}

select option:checked {
  color: #3751ff;
  font-weight: 600;
}

option:disabled {
  color: rgb(170, 170, 170) !important;
  font-weight: 500 !important;
}

select {
  overflow: hidden;
}

select::-webkit-scrollbar {
  width: 0;
}

select option {
  color: #333333 !important;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input::-ms-input-placeholder {
  color: #adadad;
}

input::placeholder {
  color: #adadad;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 27px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  /* -webkit-font-feature-settings: 'liga'; */
  -webkit-font-smoothing: antialiased;
  /* cursor: pointer; */
}

.tooltiptext {
  visibility: hidden;
  background-color: #3fbbb9;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 3px;
  z-index: 1;
  white-space: break-spaces;
  text-align: center;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  transition: 0.3s all ease;
  font-weight: 600;
  position: absolute;
  left: -15px;
  bottom: -32px;
  overflow: visible;
}

.tooltiptext::before {
  position: absolute;
  width: 10px;
  height: 10px;
  content: "";
  background: #3fbbb9;
  left: 42%;
  top: -5px;
  -webkit-backdrop-filter: #3fbbb9;
  backdrop-filter: #3fbbb9;
  transform: rotate(45deg);
  background: #3fbbb9;
  visibility: hidden;
  transition: 0.1s all ease;
  line-height: 20px;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
  visibility: visible;
  overflow: visible;
  display: block;
}

.pagination {
  padding: 10px 14px;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.warning-section-modal {
  width: 400px;
  /* height: 356px; */
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-btn {
  width: 84px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
  margin-right: 10px;
}

.confirm-btn:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.cancel-btn {
  width: 84px;
  padding-right: 5px;
  padding-left: 5px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
  margin-left: 10px;
}

.cancel-btn:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}

.concept-modal {
  max-width: 1173px !important;
  /* min-width: 650px !important; */
  width: 100% !important;
}

.publish-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.pl-5 {
  padding-left: 5px;
}

.p5 {
  padding: 5px;
}

.publish-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.text-div {
  display: flex;
  align-items: center;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.modal-content {
  padding: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.date-column {
  height: 39px !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-left: 12px !important;
  background: #ffffff;
  border: 1px solid #e0e4f0 !important;
  border-radius: 4px !important;
  /* width: 310px !important; */
}

.light-gray-text {
  color: #333333;
}

.publish-modal-button {
  background: #37841c;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin-right: 30px;
  cursor: pointer;
  padding: 10px 15px;
}
.action-icons {
  justify-content: flex-start;
  margin-top: 7px;
}

.dark-font {
  color: #333;
}

/* Media Query */
@media screen and (max-width: 992px) {
  .video {
    margin-top: 10px;
  }
  .topic {
    margin-top: 0;
  }
  .input-box {
    margin-bottom: 10px;
  }

  .test-list {
    display: block;
  }

  .column-3 {
    clear: left;
  }

  .column-3 h2:nth-child(1) {
    margin-top: 7px;
  }

  .pagination {
    margin-bottom: 10px;
  }

  .test-details {
    display: block !important;
  }
}

@media screen and (max-width: 600px) {
  /* .row{
    margin-bottom: 0;
  } */
  .add-btn {
    margin-bottom: 20px;
  }

  .input-box {
    padding-right: 0 !important;
  }

  .warning-section-modal {
    width: 300px;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
